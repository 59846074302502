:root {
  --header-height: 60px;
  --gap: 30px;
  --white: #fff;
  --dark: #272635;
  --pad: 100px;
  --big-gap: 15vw;
  --blue: #272635;
  --light: #41AFAA;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "neue-haas-unica", sans-serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-size: 16px;
  line-height: 1.37;
  background: var(--white);
  color: var(--dark);
  margin: 0;
  padding: 0 0 0 0;
  text-align: left;
  transition: color 1.5s, background-color 1.5s;
}

#page-trn {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100svh;
  background-color: #eee;
  z-index: 100;
  transform: translateY(-100%);
}

a {
  text-decoration: none;
  cursor: pointer;
  color: inherit;
}

img {
  border: none;
  max-width: 100%;
}

.img {
  width: 100%;
  display: block;
}

a,
img {
  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.dark-bg::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  display: block;
  content: "";
  z-index: 1;
}

.grid_8 {
  width: calc(66.66% - var(--gap) * 0.67);
}

.grid_6 {
  width: calc(50% - var(--gap) * 0.5);
}

.grid_4 {
  width: calc(33.33% - var(--gap) * 0.67);
  display: block;
}

.grid_5 {
  width: 40%;
}

.grid_7 {
  width: 60%;
}

.grid_3 {
  width: calc(25% - var(--gap) * 0.75);
}

.mauto {
  margin-left: auto;
  margin-right: auto;
}

.mb5 {
  margin-bottom: 5px;
}

.mb15 {
  margin-bottom: 15px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb25 {
  margin-bottom: 25px;
}

.mb30 {
  margin-bottom: 30px;
}

.mb40 {
  margin-bottom: 40px;
}

.mb50 {
  margin-bottom: 50px;
}

.mb60 {
  margin-bottom: 60px;
}

.mb80 {
  margin-bottom: 80px;
}

.pt50v {
  padding-top: 50svh;
}

.ptxl {
  padding-top: calc(1.25 * var(--pad));
}

.pbxl {
  padding-bottom: calc(1.25 * var(--pad));
}

.ptl {
  padding-top: var(--pad);
}

.pbl {
  padding-bottom: var(--pad);
}

.ptm {
  padding-top: calc(0.5 * var(--pad));
}

.pbm {
  padding-bottom: calc(0.5 * var(--pad));
}

.pts {
  padding-top: 30px;
}

.pbs {
  padding-bottom: 30px;
}

.left-align {
  text-align: left;
}

.center-align {
  text-align: center;
}

.right-align {
  text-align: right;
}

.underline {
  text-decoration: underline;
}

.max10 {
  max-width: 10em;
}

.over-hidden {
  overflow: hidden;
}

#ticker {
  text-align: left;
  display: flex;
  width: -moz-max-content;
  width: max-content;
  animation: ticker linear 60s infinite;
}

@keyframes ticker {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}
.middle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  z-index: 1;
  display: block;
}

.middle-h {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  width: 100%;
  z-index: 1;
  display: block;
}

.container {
  max-width: calc(100% - var(--gap) - var(--gap));
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 1;
}

.max430 {
  max-width: 430px;
}

.max520 {
  max-width: 520px;
}

.bg-blue {
  background-color: var(--blue);
  color: #fff;
}

.uppercase {
  text-transform: uppercase;
}

.flex-st-st {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  -moz-column-gap: 0;
       column-gap: 0;
  row-gap: 0;
  flex-wrap: wrap;
}

.flex-sb-st {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  -moz-column-gap: 0;
       column-gap: 0;
  row-gap: 0;
  flex-wrap: wrap;
}

.flex-en-st {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-direction: row;
  -moz-column-gap: 0;
       column-gap: 0;
  row-gap: 0;
  flex-wrap: wrap;
}

.flex-sb-ce {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  -moz-column-gap: 0;
       column-gap: 0;
  row-gap: 0;
  flex-wrap: wrap;
  align-self: normal;
}

.flex-ce-ce {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  -moz-column-gap: 0;
       column-gap: 0;
  row-gap: 0;
  flex-wrap: wrap;
}

.flex-col-ce-ce {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  -moz-column-gap: 0;
       column-gap: 0;
  row-gap: 0;
  flex-wrap: wrap;
}

.gap {
  gap: var(--gap);
}

.w58 {
  width: calc(58% - 20px);
  margin: 0 auto;
  display: block;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.full-height {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  -moz-column-gap: 0;
       column-gap: 0;
  row-gap: 0;
  flex-wrap: wrap;
  height: 100vh;
  height: 100svh;
  background-size: cover;
  position: relative;
}

.full-h {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  -moz-column-gap: 0;
       column-gap: 0;
  row-gap: 0;
  flex-wrap: wrap;
  height: calc(100vh - var(--header-height));
  height: calc(100svh - var(--header-height));
  background-size: cover;
  position: relative;
}

.btn {
  width: -moz-max-content;
  width: max-content;
  padding: 8px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  -moz-column-gap: 0;
       column-gap: 0;
  row-gap: 0;
  flex-wrap: wrap;
  border-bottom: 1px solid var(--light);
  color: #fff;
  letter-spacing: 0.05em;
  color: var(--light);
}

section {
  position: relative;
  z-index: 1;
}

.relative {
  position: relative;
  z-index: 1;
}

.abs-grid {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 2;
}

.pr-g {
  padding-right: var(--gap);
}

.bg {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: calc(3 * var(--pad));
  background: linear-gradient(180deg, rgba(39, 38, 53, 0) 0%, rgba(39, 38, 53, 0.01) 8%, rgba(39, 38, 53, 0.0320248) 12.5%, rgba(39, 38, 53, 0.0842758) 19.5%, rgba(39, 38, 53, 0.831522) 84.5%, #272635 100%);
}

.bg-white {
  background-color: var(--white);
  position: relative;
  height: calc(3 * var(--pad));
}

.bg-b {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: calc(3 * var(--pad));
}
.bg-b::before {
  width: 100%;
  height: 100%;
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 0;
}
.bg-b::after {
  width: 100%;
  height: 100%;
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(180deg);
  background: linear-gradient(180deg, rgba(39, 38, 53, 0) 0%, rgba(39, 38, 53, 0.015) 10%, rgba(39, 38, 53, 0.831522) 74%, #272635 100%);
  z-index: 0;
}

p {
  text-align: justify;
}

.p-mb {
  padding-right: 15px;
  margin-bottom: 15px;
}
.p-mb p {
  margin-bottom: 15px;
}
.p-mb p:last-child {
  margin-bottom: 0;
}

.logo {
  position: absolute;
  max-width: calc(100% - 400px);
  width: 65vw;
  display: block;
  left: var(--gap);
  z-index: 5;
  top: var(--gap);
}
.logo svg {
  width: 100%;
  height: auto;
  display: block;
}
.logo .abs-svg {
  position: absolute;
  left: 0;
  top: 0;
  width: 240px;
}

.toFade {
  opacity: 0;
}

.faded .toFade {
  opacity: 1;
}

header {
  position: fixed;
  top: 0;
  right: 0;
  padding: var(--gap);
  z-index: 4;
  width: 100%;
  color: #000;
  font-weight: 500;
}
header nav {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  -moz-column-gap: 0;
       column-gap: 0;
  row-gap: 0;
  flex-wrap: wrap;
  row-gap: 5px;
  text-align: right;
  font-size: 14px;
  letter-spacing: 0.05em;
  position: absolute;
  right: var(--gap);
}
header nav a {
  position: relative;
  text-transform: uppercase;
}
header nav a:after {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  bottom: -2px;
  height: 1px;
  background-color: var(--light);
  display: block;
  opacity: 0;
}
header nav a.active-page:after {
  opacity: 1;
}

.bg-blue #nav a {
  color: #fff;
}

.bg-blue .logo svg path {
  fill: #fff;
}

.bg-blue #nav-toggle span, .bg-blue #nav-toggle span::before, .bg-blue #nav-toggle span:after {
  background-color: #fff;
}

#nav-toggle {
  right: var(--gap);
  z-index: 10;
  cursor: pointer;
  width: 30px;
  height: 20px;
  display: none;
  position: absolute;
  top: var(--gap);
}

#nav-toggle span,
#nav-toggle span:after,
#nav-toggle span:before {
  cursor: pointer;
  height: 2px;
  width: 30px;
  background: var(--dark);
  position: absolute;
  display: block;
  content: "";
  top: 10px;
  transition: all 0.6s ease-in-out;
}

#nav-toggle span:before {
  top: -8px;
}

#nav-toggle span:after {
  top: auto;
  bottom: -8px;
}

#nav-toggle.active span {
  background-color: transparent !important;
}

#nav-toggle.active span:after,
#nav-toggle.active span:before {
  top: 0;
}

#nav-toggle.active span:before {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: a rotate(45deg);
}

#nav-toggle.active span:after {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: a rotate(-45deg);
}

h1,
h2,
h3 {
  font-weight: 400;
  line-height: 1.1;
  position: relative;
}

h1 {
  font-size: 100px;
  letter-spacing: -0.03em;
  line-height: 1.01;
  font-size: clamp(38px, 4.3vw, 100px);
}

h2 {
  font-size: 45px;
  font-weight: 500;
}

h3 {
  font-size: 24px;
  font-weight: 500;
}
h3.clamp {
  font-size: clamp(14px, 1.4vw, 40px);
}

h4 {
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.05em;
  color: var(--light);
  text-transform: uppercase;
}

.asp3-2 {
  aspect-ratio: 3/2;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}

.asp1-1 {
  aspect-ratio: 1/1;
  display: block;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}

.grid_3.project-grid {
  text-align: left;
}
.grid_3.project-grid img {
  aspect-ratio: 3/2;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}
.grid_3.project-grid h4 {
  padding-top: 10px;
}

.fadeInUp {
  transition: opacity 0.7s, transform 0.7s;
  opacity: 0;
  transform: translateY(15px);
}
.fadeInUp.animated {
  opacity: 1;
  transform: translateY(0);
}

.heading {
  padding: 15px 0;
  border-top: 1px solid var(--light);
  position: relative;
}

.pr-row {
  width: calc(80% - 30px);
}
.pr-row .heading:first-child {
  border: none;
}

.toggle-title {
  position: relative;
}
.toggle-title .arrow {
  right: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 8px 0;
}
.toggle-title .arrow img {
  display: block;
  transform: rotate(-180deg);
  width: 14px;
  transition: transform 0.4s;
}
.toggle-title.active img {
  transform: rotate(0);
}

.toggle-holder {
  border-bottom: 1px solid var(--light);
}
.toggle-holder .spc {
  display: block;
  height: 0;
  transition: height 0.4s;
}
.toggle-holder.active .spc {
  height: 40px;
}

.inner-toogle {
  height: 0;
  overflow: hidden;
  transition: height 0.4s;
}
.inner-toogle p {
  max-width: 520px;
}

.logos {
  display: flex;
  flex-wrap: wrap;
  gap: var(--gap);
}

.p-logo {
  background-color: #fafafa;
  width: calc(16.66% - 25px);
  min-height: 10vw;
  display: flex;
  background-position: center;
  background-size: 50% auto;
  background-repeat: no-repeat;
  background-blend-mode: multiply;
}
.p-logo img {
  display: block;
  margin: auto;
  max-width: 50%;
  max-height: 50%;
}

.close {
  position: absolute;
  top: var(--gap);
  right: var(--gap);
}

footer {
  position: relative;
  z-index: 1;
  font-size: 14px;
}

input,
textarea {
  margin: 0 auto 10px auto;
  padding: 10px 0;
  width: 100%;
  border: 0 none;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
  color: #fff;
  border-bottom: 1px solid var(--light);
  text-align: left;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 0.05em;
  resize: none;
  outline: none;
}

.err-msg {
  color: red;
  font-size: 12px;
  margin-bottom: 5px;
  transform: translateY(-5px);
}

input:-webkit-input-placeholder,
textarea:-webkit-input-placeholder {
  color: #fff;
  opacity: 1;
}

::-moz-placeholder {
  color: inherit;
  opacity: 1;
}

::placeholder {
  color: inherit;
  opacity: 1;
}

::-ms-input-placeholder {
  color: inherit;
}

input:focus::-webkit-input-placeholder,
textarea:focus::-webkit-input-placeholder {
  color: transparent;
}

input:focus:-moz-placeholder,
textarea:focus:-moz-placeholder {
  color: transparent;
}

input:focus::-moz-placeholder,
textarea:focus::-moz-placeholder {
  color: transparent;
}

input:focus:-ms-input-placeholder,
textarea:focus:-ms-input-placeholder {
  color: transparent;
}

button {
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: none;
  border: none;
}

.toPop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  height: 100svh;
  width: 100%;
  background-color: #fff;
  z-index: 9999;
  overflow-y: scroll;
  display: none;
  opacity: 0;
  transition: opacity 0.7s;
}

.toPop .inner-pop {
  height: 100%;
  padding: 80px;
  width: 1100px;
  margin: auto;
  max-width: calc(100% - 40px);
  text-align: left;
  color: var(--blue);
}

.toPop .inner-pop h3 {
  padding-top: 15px;
  font-size: 24px;
  margin-bottom: 15px;
}

.border-b {
  border-top: 1px solid var(--light);
  padding: var(--gap) 0;
}

.p-dis {
  display: flex;
  -moz-column-gap: 60px;
       column-gap: 60px;
}

.form {
  position: relative;
}

.loader {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  position: absolute;
  background-color: rgba(38, 39, 53, 0.5);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.loader > .dot {
  animation-fill-mode: forwards;
  animation-name: busy;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in;
  height: 20px;
  width: 20px;
  background: radial-gradient(circle at 65% 15%, hsl(262, 16%, 86%) 1px, hsl(262, 5%, 61%) 3%, hsl(262, 85%, 41%) 60%, hsl(262, 85%, 21%) 100%);
  border-radius: 50%;
}
.loader > .dot:nth-child(2) {
  animation-delay: 0.25s;
}
.loader > .dot:nth-child(3) {
  animation-delay: 0.5s;
}

@keyframes busy {
  0% {
    transform: translateY(0) scale(1);
    filter: drop-shadow(0 32px 12px rgba(0, 0, 0, 0.05));
  }
  100% {
    transform: translateY(100%) scale(0.75);
    filter: drop-shadow(0 2px rgba(0, 0, 0, 0.25));
  }
}
@media screen and (max-width: 1000px) {
  :root {
    --header-height: 60px;
    --gap: 15px;
    --pad: 50px;
  }
  body {
    font-size: 14px;
  }
  h2 {
    font-size: 28px;
  }
  h3 {
    font-size: 20px;
  }
  .grid_7, .grid_5, .grid_6 {
    width: 100%;
  }
  #nav {
    position: absolute;
    left: 0;
    width: 100%;
    transform: translateX(-100%);
    top: 0;
    background: #fff;
    color: var(--dark);
    transition: transform 0.5s;
    padding: 30px 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    height: 100svh;
    z-index: 1;
  }
  #nav.active {
    transform: translateX(0);
  }
  header nav a {
    position: relative;
    display: block;
    width: -moz-max-content;
    width: max-content;
    margin: 0 auto 5px auto;
  }
  #nav-toggle {
    display: block;
  }
  .w58 {
    width: 100%;
  }
  .pr-row {
    width: 100%;
  }
  .p-logo {
    width: calc(50% - 7.5px);
    min-height: 30vw;
  }
  .full-m {
    max-width: 100%;
    padding: 120px 0 0 0;
  }
  .bg-b {
    bottom: 55vw;
  }
  .abs-grid {
    position: relative;
    left: auto;
    right: auto;
    width: 100%;
    padding: 0 var(--gap);
  }
  .trn-m {
    max-width: 100%;
  }
  .trn-m .grid_7 {
    margin-top: -80px;
  }
  footer {
    font-size: 12px;
    line-height: 1.5;
  }
  .p-dis {
    display: block;
  }
  .pt-mob {
    padding-top: 30px;
  }
  .logo {
    max-width: calc(100% - 85px);
  }
  .bg-blue #nav {
    background-color: var(--blue);
  }
}/*# sourceMappingURL=index.css.map */