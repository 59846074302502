/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  overflow: hidden;
  padding-bottom: 20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.not-trn.slick-track {
  transition: 0s !important;
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  padding: 0;
  min-height: 1px;
}

.img-holder {
  height: 100vh;
  height: 100svh;
  position: relative;
  display: flex !important;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 90px 60px;
}
.img-holder.fit-holder {
  padding: 0;
}
.img-holder.fit-holder img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 0;
}
.img-holder.fit-left {
  padding: 0;
  display: flex;
  justify-content: flex-end;
}
.img-holder.fit-left img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 50%;
  height: 100%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.img-holder.fit-right {
  padding: 0;
  display: flex;
  justify-content: flex-start;
}
.img-holder.fit-right img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 50%;
  height: 100%;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.img-holder img {
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  border-radius: 15px;
  height: 100%;
}

div.cover-right {
  display: flex !important;
  justify-content: flex-end;
  align-items: center;
}

[dir=rtl] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block !important;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-disabled {
  opacity: 0.1;
}

.slick-arrow {
  display: none !important;
}

.slick-dots {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex !important;
  gap: 5px;
}
.slick-dots li {
  width: 10px;
  height: 10px;
  list-style: none;
}
.slick-dots li button {
  width: 100%;
  height: 100%;
  border: 1px solid #41AFAA;
  border-radius: 50%;
  font-size: 0;
  display: block;
  margin-right: 5px;
}
.slick-dots li.slick-active button {
  background-color: #41AFAA;
}

button {
  cursor: pointer;
}

@media screen and (max-width: 1000px) {
  .slick-dots {
    left: 15px;
  }
}/*# sourceMappingURL=slick.css.map */